<template>
  <SchoolAwayEntitlementTable
    :items="items"
    :is-busy="isBusyStore"
    :pagination="pagination"
    @navigateToIndividual="goToStaffAssignment"
  >
  </SchoolAwayEntitlementTable>
</template>

<script>
import { mapGetters } from "vuex";
import SchoolAwayEntitlementTable from "@/modules/school/components/management/away/entitlement/SchoolAwayEntitlementTable";

export default {
  name: "StaffAwayAssignment",
  components: { SchoolAwayEntitlementTable },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    /**
     * Fetch Staff list from store
     */
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchStaffList")
        .finally(() => (this.isBusy = false));
    },

    /**
     * Proceed to Staff Individual Entitlement Page
     *
     * @param data
     */
    goToStaffAssignment(data) {
      console.log(data);
      this.$router.push({
        name: "staffAwayAssignmentPage",
        query: { id: data.item.id, name: data.item.full_name },
      });
    },
  },
  computed: {
    ...mapGetters({
      items: "getStaffList",
      pagination: "getStaffListPagination",
      isBusyStore: "getStaffListBusy",
    }),
  },
};
</script>

<style scoped></style>
